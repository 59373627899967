export const SERVER_URL = "https://guarded-bayou-73677-dd056d1c6fb8.herokuapp.com/";
//export const SERVER_URL = "http://localhost:8001/";

export const DATA_STATE = {
	DATA_STATE_OK: "DATA_STATE_OK",
	DATA_STATE_LOADING: "DATA_STATE_LOADING",
};

export const NOTIFICATION_TYPES = {
	STANDARD: "STANDARD",
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
};

export const CONTACT_INFO = {
	SUPPORT_EMAIL: "soulandsoledanceshoes@gmail.com",
	SUPPORT_PHONE: "+381 62 147 8214",
	OUT_OF_STOCK_TIMEFRAME: "8",
	GENERAL_TIMEFRAME: "2 - 3",
	INTERNATIONAL_TIMEFRAME: "14+",
	COMPANY_NAME: "SERKAN OZKAN SOUL & SOLE BEOGRAD",
	REGISTRATION_NUMBER:"67759958",
	PIB:"114666831",
	BANK_ACCOUNT_DEFAULT:"160-6000002177881-35",
	//BANK_ACCOUNT_QRCODE:"000-0000000000-00000",
	BANK_ACCOUNT_QRCODE:"160-6000002177881-35",
  };