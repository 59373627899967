import "./checkout-step.css";
import { useDispatch, useSelector } from "react-redux";
import {
  useMediaQuery,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  Divider,
  Modal,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { isMobile } from "react-device-detect";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomField from "../../other/CustomField";
import {
  clearCart,
  selectDiscountDetails,
  selectItems,
  selectTotalCartPrice,
} from "../../../store/reducers/cartSlice";
import {
  selectActiveLanguage,
  selectScreenSize,
} from "../../../store/reducers/userSlice";
import { translations } from "../../../helpers/translations";
import { useNavigate } from "react-router-dom";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { getTranslation } from "../../../helpers/translationUtils";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import CustomButton from "../../other/CustomButton";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import axios from "axios";
import {
  CONTACT_INFO,
  DATA_STATE,
  NOTIFICATION_TYPES,
  SERVER_URL,
} from "../../../helpers/app.constants";
import {
  displayNotification,
  setDataState,
} from "../../../store/reducers/notificationSlice";
import {  useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { QRCodeSVG } from "qrcode.react";
import {
  generateOrderID,
  generateQRData,
} from "../../../helpers/checkoutUtils";

function CheckoutStep({ setCurrentStep }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector(selectItems);
  const totalPrice = useSelector(selectTotalCartPrice);
  const currentLanguage = useSelector(selectActiveLanguage);
  const translatedLanguages = translations[currentLanguage] || {};
  const isScreenSmall = useMediaQuery("(max-width: 1000px)") || isMobile;
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const cartEmpty = items.length === 0;
  const isMedium = useMediaQuery("(max-width: 1200px)");
  const [orderID, setOrderID] = useState(generateOrderID());
  const discountDetails = useSelector(selectDiscountDetails);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    zipCode: Yup.string()
      .required("Postal code is required")
      .matches(/^\d{5}$/, "Postal code must be 5 digits"),
    paymentMethod: Yup.string().required("Payment method is required"),
    terms: Yup.boolean()
      .oneOf([true], "Please accept the terms and conditions")
      .required("Terms acceptance is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .test("isValidPhoneNumber", "Invalid phone number", function (value) {
        const phoneNumber = parsePhoneNumberFromString(value || "", "RS");
        if (
          phoneNumber &&
          phoneNumber.isValid() &&
          phoneNumber.nationalNumber.length === 9
        ) {
          return true;
        }

        return false;
      }),
  });

  const initialValues = {
    email: "",
    name: "",
    address: "",
    city: "",
    zipCode: "",
    paymentMethod: "",
    terms: false,
    items,
    phoneNumber: "",
  };


  const handleSubmit = async (values, { setErrors }) => {
    const formattedItems = items.map((item) => ({
      productID: item.product._id,
      size: item.size,
      count: item.count,
    }));

    const orderPayload = {
      email: values.email,
      name: values.name,
      address: values.address,
      city: values.city,
      zipCode: values.zipCode,
      paymentMethod: values.paymentMethod,
      terms: values.terms,
      phoneNumber: values.phoneNumber,
      products: formattedItems,
      totalPrice,
      language: currentLanguage,
      orderID,
      discountCodeApplied: !!discountDetails.code,
    };

    console.log(orderPayload)

    try {
      dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
      await axios.post(`${SERVER_URL}order/createorder`, orderPayload);

      setCurrentStep(3);
      dispatch(clearCart());
    } catch (error) {
      dispatch(
        displayNotification({
          text: "Server error!",
          type: NOTIFICATION_TYPES.ERROR,
        })
      );
    } finally {
      dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
    }
  };

  const [showTimeoutModal, setShowTimeoutModal] = useState(false);


  const handleTimeoutModalClose = () => {
    setShowTimeoutModal(false);
    navigate("/shop");
  };

  return (
    <div
      className="checkout-step-container"
      style={{
        minHeight: isSmallScreen ? "63" : "78vh",
      }}
    >
      {cartEmpty ? (
        <div
          style={{ minHeight: isSmallScreen ? "59vh" : "75vh" }}
          className="cart-step-empty"
        >
          <div className="cart-step-empty-inner">
            <ShoppingBagOutlinedIcon
              sx={{ fontSize: isSmallScreen ? "3rem" : "5rem" }}
            />
            <p style={{ marginTop: "2vh" }}>{translatedLanguages.cartEmpty}</p>
            <Button
              onClick={() => navigate("/shop")}
              variant="outlined"
              sx={{
                margin: "2vh 0",
                color: "black",
                border: `1px solid ${theme.palette.customOppositeLighter.main}`,
                transition: "background-color 0.3s ease, transform 0.3s ease",
                width: isSmallScreen ? "90%" : "75%",
                height: "50px",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                  border: `1px solid ${theme.palette.customOppositeLighter.main}`,
                },
              }}
            >
              {translatedLanguages.continueShopping || "Continue Shopping"}
            </Button>
          </div>
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, errors, touched, submitForm }) => (
            <div
              className="checkout-step-form-container"
              style={{
                minHeight: isSmallScreen ? "63" : "78vh",
              }}
            >
              <Form
                className="checkout-step-form"
                style={{
                  fontSize: isSmallScreen ? "0.8em" : "0.9em",
                  width: isScreenSmall ? "80%" : isMedium ? "70%" : "55%",
                }}
              >
                <div
                  style={{
                    marginBottom: "20px",
                    width: "100%",
                    marginTop: "5vh",
                  }}
                >
                  <CustomField
                    name="email"
                    label={getTranslation("ordersForm.email", currentLanguage)}
                    size="small"
                    margin="4vh 0"
                    width="100%"
                    error={errors.email && touched.email}
                  />
                  <CustomField
                    name="name"
                    label={getTranslation("ordersForm.name", currentLanguage)}
                    size="small"
                    margin="4vh 0"
                    width="100%"
                    error={errors.name && touched.name}
                  />
                  <CustomField
                    name="address"
                    label={getTranslation(
                      "ordersForm.address",
                      currentLanguage
                    )}
                    size="small"
                    margin="4vh 0"
                    width="100%"
                    error={errors.address && touched.address}
                  />
                  <CustomField
                    name="city"
                    label={getTranslation("ordersForm.city", currentLanguage)}
                    size="small"
                    margin="4vh 0"
                    width="100%"
                    error={errors.city && touched.city}
                  />
                  <CustomField
                    name="zipCode"
                    label={getTranslation(
                      "ordersForm.zipCode",
                      currentLanguage
                    )}
                    size="small"
                    margin="4vh 0"
                    width="100%"
                    error={errors.zipCode && touched.zipCode}
                  />

                  <CustomField
                    name="phoneNumber"
                    label={getTranslation(
                      "ordersForm.phoneNumber",
                      currentLanguage
                    )}
                    size="small"
                    margin="4vh 0"
                    width="100%"
                    error={errors.phoneNumber && touched.phoneNumber}
                    phoneInput
                    value={values.phoneNumber}
                    onChange={(phone) => setFieldValue("phoneNumber", phone)}
                  />
                </div>

                <div className="form-control-container">
                  <div
                    style={{
                      display: "flex",
                      paddingLeft: "2vh",
                      height: "30px",
                    }}
                  >
                    {translatedLanguages.ordersForm.paymentMethod}:
                  </div>
                  <RadioGroup
                    name="paymentMethod"
                    value={values.paymentMethod}
                    onChange={(e) =>
                      setFieldValue("paymentMethod", e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="cashOnDelivery"
                      control={
                        <Radio
                          sx={{
                            marginLeft: "20px",
                            color: theme.palette.customOppositeLighter.main,
                            "&.Mui-checked": {
                              color: theme.palette.customOpposite.main,
                            },
                          }}
                        />
                      }
                      label={getTranslation(
                        "ordersForm.cashOnDelivery",
                        currentLanguage
                      )}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: isSmallScreen ? "0.7em" : "0.8em",
                        },
                      }}
                    />
                     <FormControlLabel
                      value="bankAccount"
                      control={
                        <Radio
                          sx={{
                            marginLeft: "20px",
                            color: theme.palette.customOppositeLighter.main,
                            "&.Mui-checked": {
                              color: theme.palette.customOpposite.main,
                            },
                          }}
                        />
                      }
                      label={getTranslation(
                        "ordersForm.bankAccount",
                        currentLanguage
                      )}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: isSmallScreen ? "0.7em" : "0.8em",
                        },
                      }}
                    /> 
                  </RadioGroup>
                  {errors.paymentMethod && touched.paymentMethod && (
                    <div
                      className="checkout-step-error"
                      style={{
                        color: "red",
                        fontSize: "0.75em",
                        marginTop: "22px",
                        marginLeft: "12",
                      }}
                    >
                      {errors.paymentMethod}
                    </div>
                  )}
                  {values.paymentMethod === "bankAccount" && (
                    <div className="checkout-step-bank-account">
                      <Divider orientation="horizontal" flexItem />
                      <span>
                        {translatedLanguages.ordersForm.bankAccountOption}{" "}
                        {CONTACT_INFO.BANK_ACCOUNT_DEFAULT}
                      </span>
                      <Divider orientation="horizontal" flexItem />

                      {/* Check if all required fields are filled */}
                      {values.name &&
                      values.address &&
                      values.city &&
                      values.zipCode ? (
                        <div style={{ textAlign: "center" }}>
                          <p>
                            {translatedLanguages.ordersForm.scanQrToPay ||
                              "Scan this QR code to pay via your banking app"}
                          </p>
                          <QRCodeSVG
                            value={
                              generateQRData({
                                bankAccount: CONTACT_INFO.BANK_ACCOUNT_QRCODE.replace(/[^0-9]/g, '').padStart(18, '0'),
                              amount: totalPrice.toFixed(2),
                              reference: `${orderID}`,
                              paymentCode: '189', 
                              companyName: CONTACT_INFO.COMPANY_NAME,
                              // payer: {
                              //   name: values.name,
                              //   address: values.address,
                              //    city: values.city,
                              //    zipCode: values.zipCode,
                              // },
                            })
                          }
                            size={128}
                            level={"H"}
                            includeMargin={true}
                          />
                          <p>
                            {translatedLanguages.ordersForm.orderID}: {orderID}
                          </p>
                        </div>
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          {/* Display loading spinner and error message */}
                          <div className="spinner-checkout"></div>
                          <p
                            className="checkout-step-error"
                            style={{ position: "relative" }}
                          >
                            {translatedLanguages.ordersForm
                              .fillRequiredFields ||
                              "Please fill out all the fields to generate the QR code."}
                          </p>
                        </div>
                      )}

                      <div>
                        {translatedLanguages.ordersForm.bankAccountNotification}
                      </div>

                      <Divider orientation="horizontal" flexItem />
                    </div>
                  )}
                </div>

                <div
                  className="form-control-container"
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-end",
                    width: "113%",
                    height: "50px",
                  }}
                >
                  <div>
                    <Checkbox
                      name="terms"
                      checked={values.terms}
                      onChange={() => setFieldValue("terms", !values.terms)}
                      sx={{
                        color: theme.palette.customOppositeLighter.main,
                        "&.Mui-checked": {
                          color: theme.palette.customOpposite.main,
                        },
                      }}
                    />
                    <span
                      style={{ fontSize: isSmallScreen ? "0.7em" : "0.8em" }}
                    >
                      {getTranslation(
                        "ordersForm.acceptTerms",
                        currentLanguage
                      )}
                      {errors.terms && touched.terms && (
                        <div
                          className="error-message"
                          style={{
                            color: "red",
                            fontSize: "0.75em",
                            marginLeft: "20%",
                          }}
                        >
                          {errors.terms}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </Form>

              <div
                className="cart-step-total-container"
                style={{
                  height: "10vh",
                  display: "flex",
                  flexDirection:"column",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Divider
                  sx={{
                    width: "100%",
                    backgroundColor: theme.palette.customOppositeLighter.main,
                    margin: "auto",
                  }}
                  orientation="horizontal"
                  variant="middle"
                  flexItem
                />

                <div
                  style={{
                    width: "100%",
                    height: "10vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CustomButton
                    textButton={
                      translatedLanguages.checkoutComplete || "Checkout"
                    }
                    onClick={submitForm}
                    endIcon={<ShoppingCartCheckoutIcon />}
                    width="95%"
                    fontSize="1em"
                    backgroundColor="#97E78A"
                    hoveBgColor="#65E05E"
                  />
                </div>
              </div>
            </div>
          )}
        </Formik>
      )}
      <Modal
        open={showTimeoutModal}
        onClose={handleTimeoutModalClose}
        aria-labelledby="timeout-modal"
        aria-describedby="timeout-modal-description"
      >
        <div
          className="modal-content-overlay"
          style={{
            border: `1px solid ${theme.palette.customOppositeLighter.main}`,
            width: isSmallScreen ? "80%" : isMedium ? "50%" : "40%",
            padding: "2em",
            backgroundColor: theme.palette.background.paper,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            position: "relative",
            margin: "auto",
            top: "25vh",
          }}
        >
          <CloseIcon
            onClick={handleTimeoutModalClose}
            sx={{
              fontSize: "1.3em",
              cursor: "pointer",
              position: "absolute",
              top: "10px",
              right: "10px",
              color: theme.palette.customOppositeLighter.main,
            }}
          />
          <h2>{translatedLanguages.timeoutMessage || "Session Expired"}</h2>
          <p>
            {translatedLanguages.sessionTimeoutExplanation ||
              "Your session has expired. Please refresh the page or continue shopping."}
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              textButton={
                translatedLanguages.continueShopping || "Continue Shopping"
              }
              onClick={handleTimeoutModalClose}
              width="100%"
              backgroundColor="#97E78A"
              hoverBgColor="#65E05E"
              color="black"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CheckoutStep;
