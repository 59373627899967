import "./cart-step.css";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { translations } from "../../../helpers/translations";
import { Tooltip, Divider, useMediaQuery, Modal } from "@mui/material";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  addItemToCart,
  removeItemsFromCart,
  selectItems,
  selectTotalCartPrice,
} from "../../../store/reducers/cartSlice";
import {
  selectActiveLanguage,
  selectScreenSize,
} from "../../../store/reducers/userSlice";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import CustomButton from "../../other/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import useValidateDiscountCode from "../../../helpers/hooks/useValidateDiscountCode";
import CheckIcon from "@mui/icons-material/Check";
import useSessionTimeout from "../../../helpers/hooks/useSessionTimeout";

function CartStep({ setCurrentStep }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector(selectItems);
  const [discountCode, setDiscountCode] = useState("");
  const [discountError, setDiscountError] = useState("");
  const [applyGlobalDiscount, setApplyGlobalDiscount] = useState(false);
  const [globalDiscountPercentage, setGlobalDiscountPercentage] = useState(0);
  const [codeTotalPrice, setCodeTotalPrice] = useState(null);
  const { isSessionExpired, resetSession } = useSessionTimeout();
  const cartEmpty = items.length === 0;
  const currentLanguage = useSelector(selectActiveLanguage);
  const translatedLanguages = translations[currentLanguage] || {};
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const isMedium = useMediaQuery("(max-width: 1000px)");

  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const isDiscountValid = useValidateDiscountCode(discountCode);

  useEffect(() => {
    if (applyGlobalDiscount) {
      const originalTotal = items.reduce(
        (sum, item) => sum + item.product.price * item.count,
        0
      );
      const newTotal =
        originalTotal - (originalTotal * globalDiscountPercentage) / 100;
      setCodeTotalPrice(Math.max(newTotal, 0));
    } else {
      setCodeTotalPrice(null);
    }
  }, [applyGlobalDiscount, globalDiscountPercentage, items]);

  const finalPrice = useSelector(selectTotalCartPrice);

  const handleTimeoutModalClose = () => {
    resetSession(() => {
      navigate("/shop");
    });
  };

  const handleApplyCodeDiscount = () => {
    if (!discountCode.trim()) {
      setDiscountError("Enter a discount code.");
      return;
    }

    if (isDiscountValid.isValid) {
      setGlobalDiscountPercentage(isDiscountValid.discountAmount); // Set the discount percentage
      setApplyGlobalDiscount(true);
      setDiscountError(""); // Clear previous error
    } else {
      setDiscountError("Invalid discount code.");
    }
  };

  const headers = [
    { key: "number", label: translatedLanguages.number },
    { key: "image", label: translatedLanguages.image },
    { key: "name", label: translatedLanguages.name },
    { key: "size", label: translatedLanguages.size },
    { key: "price", label: translatedLanguages.price },
    { key: "quantity", label: translatedLanguages.quantity },
    { key: "actions", label: translatedLanguages.actions },
  ];

  const addToCartHandler = (item) => {
    dispatch(addItemToCart({ product: item.product, size: item.size }));
  };

  const removeFromCartHandler = (item) => {
    dispatch(removeItemsFromCart({ id: item.product._id, size: item.size }));
  };

  return (
    <div
      className="cart-step-container"
      style={{
        minHeight: isSmallScreen ? "95vh" : "80vh",
      }}
    >
      {cartEmpty ? (
        <div
          style={{ minHeight: isSmallScreen ? "59vh" : "75vh" }}
          className="cart-step-empty"
        >
          <div className="cart-step-empty-inner">
            <ShoppingBagOutlinedIcon
              sx={{ fontSize: isSmallScreen ? "3rem" : "5rem" }}
            />
            <p style={{ marginTop: "2vh" }}>{translatedLanguages.cartEmpty}</p>

            <CustomButton
              textButton={
                translatedLanguages.continueShopping || "Continue Shopping"
              }
              onClick={() => navigate("/shop")}
              endIcon={<ShoppingCartCheckoutIcon />}
              width="100%"
              display="flex"
              justifyContent="center"
            />
          </div>
        </div>
      ) : (
        <div
          className="cart-step-products-container"
          style={{
            fontSize: isSmallScreen ? "0.9em" : "1em",
            minHeight: isSmallScreen ? "59vh" : isMedium ? "72vh" : "75vh",
          }}
        >
          <div style={{ padding: isSmallScreen ? "7%" : "5%" }}>
            <div className="cart-step-header">
              {headers.map((header) => (
                <div className="cart-step-column" key={header.key}>
                  <p className="cart-step-column-h">{header.label}</p>
                </div>
              ))}
            </div>

            <Divider />
            <div className="cart-step-items">
              {items.map((item, index) => {
                const priceToDisplay =
                  (item.product.discountedPrice || item.product.price) *
                  item.count;

                return (
                  <div
                    className="cart-step-item"
                    key={`${item.product._id}-${item.size}`}
                  >
                    <div className="cart-step-column">{index + 1}</div>
                    <div className="cart-step-column">
                      <img
                        src={item.product.image}
                        alt={item.product.name}
                        className="cart-step-item-image"
                      />
                    </div>
                    <div className="cart-step-column">{item.product.name}</div>
                    <div className="cart-step-column">{item.size}</div>
                    <div className="cart-step-column">
                      {Number(priceToDisplay).toLocaleString("de-DE")},00
                    </div>
                    <div className="cart-step-column">{item.count}</div>
                    <div className="cart-step-column">
                      <Tooltip title={translatedLanguages.remove || "Remove"}>
                        <RemoveOutlinedIcon
                          onClick={() => removeFromCartHandler(item)}
                          className="cart-item-action-icon"
                        />
                      </Tooltip>
                      <Tooltip title={translatedLanguages.add || "Add"}>
                        <AddOutlinedIcon
                          onClick={() => addToCartHandler(item)}
                          className="cart-item-action-icon"
                        />
                      </Tooltip>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <Divider
              sx={{
                width: "100%",
                backgroundColor: theme.palette.customOppositeLighter.main,
                margin: "auto",
                // margin: "10px 0 10px 0",
              }}
              orientation="horizontal"
              variant="middle"
              flexItem
            />
            <div>
            <div
              className="cart-step-total-container"
              style={{
                minHeight: "100px",
                flexDirection: isSmallScreen ? "column" : "row",
                justifyContent: "space-between",
                fontSize: isSmallScreen
                  ? "1.2em"
                  : isMedium
                  ? "1.3em"
                  : "1.5em ",
              }}
            >
                <div
                  style={{
                    width: isSmallScreen ? "100%" : "50%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <label
                    htmlFor="discount-code"
                    style={{
                      fontSize: "0.6em",
                      marginLeft: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {translatedLanguages.discountCode || "Discount Code"}
                    {isDiscountValid.isValid && (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CheckIcon
                          style={{
                            color: "green",
                            fontSize: "1rem",
                            marginRight: "5px",
                          }}
                        />
                        <p
                          style={{
                            color: "red",
                            fontSize: "0.8rem",
                            fontWeight: "bold",
                          }}
                        >
                          -{isDiscountValid.discountAmount}%
                        </p>
                      </span>
                    )}
                  </label>

                  {discountError && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "0.5em",
                        margin: "2px 0 2px 10px",
                      }}
                    >
                      {discountError}
                    </p>
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "90%",
                        margin: "5px 0",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          flexDirection: "column",
                        }}
                      >
                        <input
                          id="discount-code"
                          type="text"
                          value={discountCode}
                          onChange={(e) => setDiscountCode(e.target.value)}
                          placeholder="Discount Code"
                          style={{
                            width: "80%",
                            height: "25px",
                            padding: "0 10px",
                            fontSize: "0.6em",
                            borderRadius: "2px",
                            border: `1px solid ${
                              discountError
                                ? "red"
                                : theme.palette.customOppositeLighter.main
                            }`,
                            outline: "none",
                            transition: "border-color 0.3s ease",
                          }}
                        />
                      </div>
                      <CustomButton
                        textButton={translatedLanguages.applyFilters || "Apply"}
                        onClick={handleApplyCodeDiscount}
                        style={{
                          backgroundColor: "white", // Button background
                          color: "black", // Text color
                          borderRadius: "4px",
                          fontWeight: "bold",
                          fontSize: "0.4em",
                          padding: "5px 2px",
                          cursor: "pointer",
                          lineHeight: "4px",
                          height: "25px",
                        }}
                        hoveBgColor="#eeeee" // Background color on hover
                        hoverColor="black" // Text color on hover
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: isSmallScreen ? "100%" : "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    flexWrap: "wrap",
                    alignContent: "center",
                    margin: isSmallScreen && "10px 0",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.3em",
                    }}
                  >
                    {translatedLanguages.totalLeft}&nbsp;
                  </span>
                  <span
                    style={{
                      marginRight: "20px",
                      color:
                        isDiscountValid.isValid && discountCode
                          ? "red"
                          : "#00AC11",
                      fontWeight: "bold",
                      fontSize: "1.3em",
                    }}
                  >
                    {finalPrice.toLocaleString("de-DE")},00 RSD
                  </span>
                </div>
       
             
            </div>
            <Divider
              sx={{
                width: "100%",
                backgroundColor: theme.palette.customOppositeLighter.main,
                margin: "auto",
                // margin: "10px 0 10px 0",
              }}
              orientation="horizontal"
              variant="middle"
              flexItem
            />
            <div
                style={{
                  width: "100%",
                  margin: "20px 0px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CustomButton
                  textButton={
                    translatedLanguages.checkout || "Proceed to Checkout"
                  }
                  onClick={() => setCurrentStep((currentStep) => currentStep + 1)}
                  width={"95%"}
                  backgroundColor="#97E78A"
                  hoverBgColor="#65E05E"
                  fontSize="1.2em"
                />
              </div>
          </div>
          </div>
        </div>
      )}

      <Modal
        open={showTimeoutModal}
        onClose={handleTimeoutModalClose}
        aria-labelledby="timeout-modal"
        aria-describedby="timeout-modal-description"
      >
        <div
          className="modal-content-overlay"
          style={{
            border: `1px solid ${theme.palette.customOppositeLighter.main}`,
            width: isSmallScreen ? "80%" : isMedium ? "50%" : "40%",
            padding: "2em",
            backgroundColor: theme.palette.background.paper,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            position: "relative",
            margin: "auto",
            top: "25vh",
          }}
        >
          <CloseIcon
            onClick={handleTimeoutModalClose}
            sx={{
              fontSize: "1.3em",
              cursor: "pointer",
              position: "absolute",
              top: "10px",
              right: "10px",
              color: theme.palette.customOppositeLighter.main,
            }}
          />
          <h2>{translatedLanguages.timeoutMessage || "Session Expired"}</h2>
          <p>
            {translatedLanguages.sessionTimeoutExplanation ||
              "Your session has expired. Please refresh the page or continue shopping."}
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              textButton={
                translatedLanguages.continueShopping || "Continue Shopping"
              }
              onClick={handleTimeoutModalClose}
              width="100%"
              backgroundColor="#97E78A"
              hoverBgColor="#65E05E"
              color="black"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CartStep;
